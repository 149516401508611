import React, { useEffect, useState, useCallback,useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './StoreList.css';
import { GetFilteredStores, GetDictionaries } from '../../api/StoreService';
import { IStore } from '../../types/IStore';
import { Helmet } from 'react-helmet';
import Loader from '../loader';
import { IDictionaryOption } from '../../types/IDictionaryOption';
import { ISearchRequest } from '../../types/ISearchRequest';
import StoreCard from './StoreCard';

const StoreList: React.FC = () => {
    const [stores, setStores] = useState<IStore[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [listingLoading, setListingLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [genders, setGenders] = useState<IDictionaryOption[]>([]);
    const [tags, setTags] = useState<IDictionaryOption[]>([]);
    const [clothesTypes, setClothesTypes] = useState<IDictionaryOption[]>([]);
    const [selectedGenders, setSelectedGenders] = useState<string[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [selectedClothesTypes, setSelectedClothesTypes] = useState<string[]>([]);
    const [showAllClothesTypes, setShowAllClothesTypes] = useState<boolean>(false);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [isFiltered, setIsFiltered] = useState<boolean>(false);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [totalPages, setTotalPages] = useState<number>(1);
    
    const [title, setTitle] = useState<string>('');
    const [filterSectionTitle, setFilterSectionTitle] = useState<string>('');
    const [canonical, setCanonical] = useState<string>('');
    
  const headingSectionRef = useRef<HTMLDivElement>(null);
  const searchSectionRef = useRef<HTMLDivElement>(null);

    const location = useLocation();
    const navigate = useNavigate();

    const fetchStores = async () => {
        if (!isInitialized)
        return;

        setListingLoading(true);
        try {
            const requestData : ISearchRequest=
                {
                    name : searchTerm,
                    clothesTypesCodes : selectedClothesTypes,
                    gendersCodes : selectedGenders,
                    tagsCodes: selectedTags,
                    onlyToShow : true,
                    urlName : '',
                    pageNumber: currentPage,
                    pageSize: pageSize
                };
            const data = await GetFilteredStores(requestData);
            setTotalPages(Math.ceil(data.allStoresCount/pageSize));
            setStores(data.stores);
        } catch (error) {
            console.error('Error fetching stores:', error);
            setError('Ups. Coś poszło nie tak. Spróbuj odświeżyć stronę.');
        } finally {
            setListingLoading(false);
        }
    };

    const debounceFetchStores = useCallback(
        debounce(async () => {
            await fetchStores();
        }, 300),
        [searchTerm, selectedGenders, selectedClothesTypes, selectedTags]
    );

    useEffect(() => { 
        const fetchFilterOptions = async () => {
        try {
            const response = await GetDictionaries();
            setGenders(response.genders);
            setClothesTypes(response.clothesTypes);
            setTags(response.tags);
        } catch (error) {
            console.error('Error fetching filter options:', error);
        }

        };

        const initializeAndFetch = async () => {
            await fetchFilterOptions();

            setLoading(true);

            const params = new URLSearchParams(location.search);
            const search = params.get('name') || '';
    
            const pathSegments = location.pathname.split('/').filter(Boolean);
            if (pathSegments.length > 1) {
                const clothesTypeSegment = pathSegments.find((ct) => ct.endsWith(';tc')) || '';
                const genderSegment = pathSegments.find((g) => g.endsWith(';g')) || '';
                const tagsSegment = pathSegments.find((t) => t.endsWith(';t')) || '';
    
                if (clothesTypeSegment) {
                    const clothesTypeParam = clothesTypeSegment.replace(';tc', '');
                    setSelectedClothesTypes(clothesTypeParam.split(',').filter(Boolean));
                }
    
                if (genderSegment) {
                    const genderParam = genderSegment.replace(';g', '');
                    setSelectedGenders(genderParam.split(',').filter(Boolean));
                }

                if (tagsSegment) {
                    const tagsParam = tagsSegment.replace(';t', '');
                    setSelectedTags(tagsParam.split(',').filter(Boolean));
                }
            }
    
            setSearchTerm(search);
    
            setLoading(false);
            setIsInitialized(true);
            
            await fetchStores();
        };

        setCurrentPage(1);
        initializeAndFetch();
    }, []);

    useEffect(() => {
        const scrollToResults = () => {
            const resultSection = document.getElementById('bodySection');
            if (resultSection) {
                resultSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        };
    
        if (isFiltered && window.innerWidth > 768) {
            scrollToResults();
        }
    }, [isFiltered]);

    useEffect(() => {
        let lastScrollY = window.scrollY;
    
        const handleScroll = () => {
            if (window.innerWidth > 768 && window.scrollY > lastScrollY) {
                if (headingSectionRef.current && searchSectionRef.current) {
                    const headingRect = headingSectionRef.current.getBoundingClientRect();
    
                    if (headingRect.bottom <= window.innerHeight && headingRect.top >= 0) {
                        searchSectionRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                        });
                    }
                }
            }
    
            lastScrollY = window.scrollY;
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {      
        if (isInitialized) {
            fetchStores();
        }
    }, [location.search, isInitialized]);

    useEffect(() => {
      updateUrl();
    }, [searchTerm, selectedGenders, selectedTags, selectedClothesTypes, isInitialized]);

    //TODO rozdzielić ustawianie h1 dla filtrów
    const updateUrl = () => {
        const genderString = selectedGenders.join(',');
        const clothesTypeString = selectedClothesTypes.join(',');
        const tagsString = selectedTags.join(',');

        let path = '';
        const newTitle='Szafa Polska - Polskie Marki Odzieżowe';
        const newFilterSectionTitle = 'Wyniki wyszukania';
        setFilterSectionTitle(newFilterSectionTitle);

        const selectedFilters=[...selectedClothesTypes
            .map(code => clothesTypes.find(g => g.code === code)?.name || '')
            .filter(name => name),...selectedGenders
            .map(code => genders.find(g => g.code === code)?.name || '')
            .filter(name => name),...selectedTags
            .map(code => tags.find(t => t.code === code)?.name || '')
            .filter(name => name)];

        if (clothesTypeString) {
            path += `/${clothesTypeString};tc`;

            var polishForm='polskie';
            
            if (['bizuteria', 'bielizna', 'odziez-sportowa'].includes(selectedClothesTypes[0]))
            {
                polishForm='polska';
            }

            setFilterSectionTitle(`Wyniki wyszukania - ${polishForm} ${selectedClothesTypes
            .map(code => clothesTypes.find(g => g.code === code)?.name || '')
            .filter(name => name).join(', ')}`);
        }

        if (genderString) {
            path += `/${genderString};g`;
        }

        if (tagsString) {
            path += `/${tagsString};t`;
        }

        const params = new URLSearchParams();
        if (searchTerm) params.set('name', searchTerm);

        if (path || searchTerm) {
            const paramPart=`/filtry${path}${params.toString()!=''? `?${params.toString()}`:''}`;
            setTitle(`${newTitle} - ${selectedFilters.join(', ')}`);
            setCanonical(`${window.location.origin}${paramPart}`);
            setIsFiltered(true);
            navigate(`${paramPart}`, { replace: true });
        } else {
            setTitle(`${newTitle}`);            
            setCanonical(`${window.location.origin}`);
            setIsFiltered(false);
            navigate(`/`, { replace: true });
        }
    };

    useEffect(() => {
        debounceFetchStores();
    }, [searchTerm, selectedClothesTypes, selectedGenders, selectedTags]);

    const handleClearFilters = () => {
        setSelectedGenders([]);
        setSelectedClothesTypes([]);
        setSelectedTags([]);
        setSearchTerm("");
    };

    const toggleGender = (gender: string) => {
        setSelectedGenders((prev) => {
            const updated = prev.includes(gender)
                ? prev.filter((g) => g !== gender)
                : [...prev, gender];
            return updated;
        });
        setCurrentPage(1);
    };

    const toggleTags = (tag: string) => {
        setSelectedTags((prev) => {
            const updated = prev.includes(tag)
                ? prev.filter((g) => g !== tag)
                : [...prev, tag];
            return updated;
        });
        setCurrentPage(1);
    };

    const toggleClothesType = (type: string) => {
        setSelectedClothesTypes((prev) => {
            const updated = prev.includes(type)
                ? prev.filter((t) => t !== type)
                : [...prev, type];
            return updated;
        });
        setCurrentPage(1);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            const filtersSection = document.getElementById('filters');
            if (filtersSection && currentPage>1) {
                window.scrollTo({
                    top: filtersSection.offsetTop,
                    behavior: 'smooth'
                });
            }
        }, 100);
    
        fetchStores();
    
        return () => clearTimeout(timer);
    }, [currentPage]);

    const visibleClothesTypes = showAllClothesTypes ? clothesTypes : clothesTypes.slice(0, 15);

    const FilterSection = () => {
        return (
            <div id="filters" className="filters-sidebar">
                <h2 className="title">
                   <span className="desktop-only">Filtry</span>  
                   <span className="mobile-only">Filtruj Polskie Marki</span> 
                    {isFiltered && (
                        <button className="clear-filters" onClick={() => handleClearFilters()}>
                            Wyczyść
                        </button>
                    )}
                </h2>
                <div className="chips-section">
                    <div className="chips-filter gender-filters">
                        {genders.map((gender) => (
                            <button
                                key={gender.code}
                                className={`filter-chip ${selectedGenders.includes(gender.code) ? 'selected' : ''}`}
                                onClick={() => toggleGender(gender.code)}
                                onTouchStart={() => toggleGender(gender.code)}
                                aria-label={`polskie ubrania ${gender.name}`}
                            >
                                {gender.name}
                            </button>
                        ))}
                    </div>
    
                    <div className="chips-filter clothes-type-filters">
                        {visibleClothesTypes.map((type) => (
                            <button
                                key={type.code}
                                className={`filter-chip ${selectedClothesTypes.includes(type.code) ? 'selected' : ''}`}
                                onClick={() => toggleClothesType(type.code)}
                                onTouchStart={() => toggleClothesType(type.code)}
                                aria-label={`polskie ${type.name}`}
                            >
                                {type.name}
                            </button>
                        ))}
                        {clothesTypes.length > 10 && (
                            <button
                                onClick={() => setShowAllClothesTypes(!showAllClothesTypes)}
                                className="show-more-button"
                            >
                                {showAllClothesTypes ? 'mniej -' : 'więcej +'}
                            </button>
                        )}
                    </div>
    
                    <div className="chips-filter tags-filters">
                        {tags.map((tag) => (
                            <button
                                key={tag.code}
                                className={`filter-chip ${selectedTags.includes(tag.code) ? 'selected' : ''}`}
                                onClick={() => toggleTags(tag.code)}
                                onTouchStart={() => toggleTags(tag.code)}
                                aria-label={`polskie ${tag.name}`}
                            >
                                #{tag.name}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        );
    };   

    const ListingSection = () => {
        return (
            <div aria-label="Lista polskich sklepów z ubraniami">
                <div className="store-list">
                {stores.map((store) => (
                    <StoreCard key={store.id} store={store} /> 
                ))}
                </div>
            </div>
        );
    };

    if(loading){
      return <Loader />
    }

    const PagingSection = () => (
        <div className="pagination">
        <button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
            &lt;
        </button>

        {[...Array(totalPages)].map((_, index) => {
            const pageNumber = index + 1;
            return (
                <button
                    key={pageNumber}
                    onClick={() => setCurrentPage(pageNumber)}
                    className={currentPage === pageNumber ? 'active' : ''}
                >
                    {pageNumber}
                </button>
            );
        })}

        <button onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
            &gt;
        </button>
    </div>
    );

    function scrollToSection() {
        const target = document.getElementById('bodySection');
        
        target?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    return (
        <div className="container">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content="Odkryj ubrania polskich marek. Sprawdź naszą listę polskich sklepów i marek odzieżowych i znajdź ulubione miejsca na zakupy odzieżowe! Zakupy na Twoich zasadach, zgodne z Twoimi wartościami." />
                <meta name="keywords" content="sklepy odzieżowe, polskie marki, moda, zakupy online, ubrania, ubrania dla kobiet, ubrania dla dzieci, ubrania dla mężczyzn, polskie ubrania" />
            </Helmet>
            <link rel="canonical" href={canonical} />
            <div className='heading' ref={headingSectionRef}>
                <h1 className="title">Przegląd Polskich Marek</h1>
                <h2 className='subtitle'>Odkrywaj ubrania produkowane w Polsce. Wspieraj polskich przedsiębiorców.</h2>
                <button className="scrollButton desktop-only" onClick={() => scrollToSection()}>Przeglądaj</button>
            </div>
            <div className="layout" id="bodySection" ref={searchSectionRef}>
                <div>
                    {error && genders.length > 0 && clothesTypes.length > 0 ? 
                    ( <div className="error">Nie udało się załadować filtrów. Odśwież stronę.</div>
                    ) : (
                        <FilterSection />                
                    )}
                </div>
                <div className='results' id="result">
                    <h3 className='results-title'>{filterSectionTitle}</h3>
                    {error && <div className="error">{error}</div>}
                    {!listingLoading && !error && stores.length === 0 && 
                        <div className="no-stores">
                            <p> Nie znaleziono sklepów spełniających wybrane kryteria </p>
                            <button onClick={() => handleClearFilters()}>Wyczyść filtry</button>
                        </div>}
                    {listingLoading ? <Loader /> : <ListingSection />}
                    {totalPages>1 && <PagingSection/>}
                </div>
            </div>
        </div>
    );
};

function debounce(func: Function, delay: number) {
    let timeoutId: NodeJS.Timeout | null;
    return function (...args: any) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
}

export default StoreList;
