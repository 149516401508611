import axios from 'axios';
import {IStore} from '../types/IStore';
import {server } from "./Clients";
import {IDictionariesResponse} from '../types/IDictionariesResponse';
import {IContactForm} from '../types/IContactForm';
import { ISearchRequest } from '../types/ISearchRequest';
import { IStoreDetails } from '../types/IStoreDetails';
  
interface StoresResponse {
    stores: IStore[];
    allStoresCount : number;
  }

const apiClient = axios.create({
    baseURL: server
});

export const GetFilteredStores=(searchRequest: ISearchRequest) => 
      apiClient.post<StoresResponse>('/store/_search',searchRequest)
        .then(response => response.data);

export const GetDictionaries=() => apiClient.get<IDictionariesResponse>('/store/dictionaries')
    .then(response => response.data);

export const SendQuestion = (question: IContactForm) => apiClient.post('/contact', question);
export const GetStoreByUrlName=(urlName: string) => apiClient.get<IStoreDetails>(`/store/${urlName}`).then(response => response.data);

